.App {
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  min-height: 100vh;
}
i {
  content: "\00A3";
  font-family: "FontAwesome";
  font-weight: normal;
}
.bg-insta {
  border-radius: 10px;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  color: white;
}
.bg-intro-gradient {
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  background-color: #FAD961;
  background-image: linear-gradient(190deg, #f3c51f 0%, #F76B1C 100%);
  

}
.bg-header {
  background-image: linear-gradient(to right, #434343 40%, black 100%);
  background-image: linear-gradient(to right, #2a0680 0%, #00c9ff 100%);
}
.mmf-logo-sm {
  /* height: ; */
}
.no-text-decoration {
  text-decoration: none;
}
.img-preview {
  width: 80px;
  height: 50px;
  object-fit: scale-down;
}
.pointer {
  cursor: pointer;
}
.text-mmf {
  color: #2fc49e;
}
.tribe-img {
  width: 100%;
  object-fit: scale-down;
  cursor: pointer;
}
.tribe-body {
  max-height: 200px;
  overflow: hidden;
  width: 100%;
}
.tribe-body-expanded {
  /* max-height: 200px; 
  overflow: hidden; */
  width: 100%;
}
.patreon-posts {
  height: 60px;
  background-color: #ff5900;
  font-size: xx-large;
}
.mmf-posts {
  height: 60px;
  background-color: #2fc49e;
  font-size: xx-large;
}

.footer {
  background-color: rgb(255, 196, 87);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}
.patreon-post {
  width: 100%;
  overflow-wrap: break-word;
}
.patreon-post img {
  /* display: none; */
  width: 100% !important;
  object-fit: scale-down;
}
.pbody-cont {
  word-break: break-all;
}
.pbody {
  width: 100%;
  inline-size: 100%;
  overflow-wrap: break-word;
}
.creator-page-bundles {
  min-height: 50vh;
}

#posts {
  min-height: 100vh;
}
