.skeleton-badge-1 {
    width: 60px;
    height: 20px;
    border-radius: 5px;
    animation: skeleton-loading 1s linear infinite alternate;

}
.skeleton-badge-2 {
    width: 50px;
    height: 20px;
    border-radius: 5px;
    animation: skeleton-loading 1s linear infinite alternate;
    
}

@keyframes skeleton-loading {
    0% {
      background-color: hsl(219, 13%, 78%);
    }
    
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }