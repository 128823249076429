.bundle-card {
  position: relative;
  min-height: 400px;
  
}
.creator-name {
  color: #396EBF;
}
.card-desc {
    max-height: 200px;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    cursor: pointer;
    overflow: hidden;
    transition: 500ms;
   text-align: justify;
  }
  .card-desc-clicked {
    cursor: pointer;
    text-align: justify;
  }
  .btn-orange {
    
    background-color: rgb(255, 72, 0);
    color: white;
  }
  .btn-orange {
    color: rgb(255, 72, 0);
    border: 1px solid rgb(255, 72, 0);
    background-color: white;

  }
  .text-orange {
    color: rgb(255, 72, 0);
  }
  .btn-orange:hover {
    background-color: rgb(255, 72, 0); 

    color: white;
    border: 1px solid white;

  }
  .btn-mmf {
    color: #2FC49E;
    background-color: white;
    border: 1px solid #2FC49E;
  }
  .btn-mmf:hover {
    background-color: #2FC49E ;
    color: white;
    border: 1px solid white;

  }
  .btn-website {
    color: #133aff;
    background-color: white;
    border: 1px solid #133aff;
  }
  .btn-website:hover {
    background-color: #133aff ;
    color: white;
    border: 1px solid white;
  }
  
  .carousel-img {
    
    width: 100%;
    height: 300px;
    object-fit: cover;
    
    object-position: top center;
    
  }
  
  .invisible {
    visibility: hidden;
    height: 1px;
    width: 1px;
  }
  .carousel-img:hover {
    cursor: zoom-in;
    
    
  }
  .image-viewer {
    z-index: 2000;
  }
  .save-button:hover {
    cursor: pointer;
  }
  .skeleton-1 {
    width: 100%;
    height: 290px;
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
  }
  .skeleton-2 {
    width: 100%;
    height: 50px;
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
  }
  .skeleton-3 {
    width: 100%;
    height: 150px;
    opacity: .7;
    animation: skeleton-loading 700ms linear infinite alternate;
  }
  .see-pics {
    color: rgb(212, 85, 0);
    transition: all 2000ms;
  }
  .see-pics:hover {
    color: rgb(0, 39, 212);
    cursor: pointer;
  }
  .skeleton-img {
    /* position: absolute;
    top: 20px;
    right: 20px;
    left: 20px; */
    /* width: 100%; */
    /* z-index: 1060; */
  }

  
  /* https://codepen.io/nxworld/pen/oLdoWb */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 600 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
/* Different positions */


  @keyframes skeleton-loading {
    0% {
      background-color: hsl(219, 13%, 78%);
    }
    
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }