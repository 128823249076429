.banner-img {
    width: 100%;
    transition: all 300ms;
    object-fit: scale-down;
}
.banner-col {
    position: relative;
}
.banner-img:hover {
    cursor: pointer;
    transform: scale(1.05);
}
.cross-promo {
    font-size: small;
}
.page-banner-img {
    width: 100%;
    height: 120px;
    object-fit: cover;
}
.page-banner-cont {
    position: relative;
}
.page-banner-text {
    position: absolute;
    right: 20px;
    bottom: 0;
    background-color: black;
    border-radius: 5px;
    padding: 5px;
}