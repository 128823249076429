.header-link {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 8.5s;
  
    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
  }
  
  .header-links {
    text-decoration: none;
  }
  .header-links:hover {
    text-decoration: underline;
  }
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    1% { transform: translate(-1px, -2px) rotate(-1deg); }
    2% { transform: translate(-px, 0px) rotate(1deg); }
    3% { transform: translate(px, 2px) rotate(0deg); }
    4% { transform: translate(1px, -1px) rotate(1deg); }
    5% { transform: translate(-1px, 2px) rotate(-1deg); }
    6% { transform: translate(-px, 1px) rotate(0deg); }
    7% { transform: translate(px, 1px) rotate(-1deg); }
    8% { transform: translate(-1px, -1px) rotate(1deg); }
    9% { transform: translate(0px, 0px) rotate(0deg); }
    100% { transform: translate(0px, 0px) rotate(0deg); } 
  }