.skeleton-row {
    height: 50px;
    background-color: white;
    border: 1px solid rgb(201, 201, 201);
    width: 100%;
    animation: skeleton-outline-loading 700ms linear infinite alternate;
    

}
.skeleton-bar {
    height: 20px;
    width: 100%;
    animation: skeleton-loading 700ms linear infinite alternate;
}
.chevron {
    font-size: 30px;
    color: rgb(201, 201, 201);
    animation: skeleton-outline-loading 700ms linear infinite alternate;

}
@keyframes skeleton-loading {
    0% {
      background-color: hsl(219, 13%, 78%);
    }
    
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  @keyframes skeleton-outline-loading {
    0% {
      border-color: hsl(219, 13%, 78%);
      color: hsl(219, 13%, 78%);
    }
    
    100% {
      border-color: hsl(200, 20%, 95%);
      color: hsl(219, 13%, 78%);
    }
  }

  .creator-thumb {
    width: 100%;
    object-fit: scale-down;
  }