.card-content-box {
  position: relative;
}
.card-creator-name {
  color: white;
}
.card-info-box {
  position: absolute;
  top: 70%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.808) 70%, rgba(158,125,1,0) 100%);
}
.card-bottom-row {
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  padding: 10px;
}
.card-price {
  
  
  color: white;
}
.creator-link {
  font-size: x-large;

  color: white;
}
.expand-icon {
   position: absolute;
  top: 5px;
  right: 5px;
  background-color: black;
  padding: 6px;
  border-radius: 10px;
   color: white; 
}
.new-save-button {
  background-color: black;

  color: white;
}
.card-date {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.747);
  padding: 5px;
  border-radius: 10px;
}
.new-bundle-card {
  position: relative;
  height: 300px;
  /* min-height: 400px; */
  box-sizing: border-box;
}
.absolute-promoted {
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: 45%;
  background-color: rgba(0, 0, 0, 0.712);
  padding: 5px;
  border-radius: 10px;
  /* right: 50%; */
  /* text-align: center; */
}
.new-creator-name {
  color: #396ebf;
}
.new-card-desc {
  max-height: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  cursor: pointer;
  overflow: hidden;
  transition: 500ms;
  text-align: justify;
}
.new-card-desc-clicked {
  cursor: pointer;
  text-align: justify;
}

.new-btn-orange {
  background-color: rgb(255, 72, 0);
  color: white;
}
.new-btn-orange {
  color: rgb(255, 72, 0);
  border: 1px solid rgb(255, 72, 0);
  background-color: white;
}
.new-text-orange {
  color: rgb(255, 72, 0);
}
.new-btn-orange:hover {
  background-color: rgb(255, 72, 0);

  color: white;
  border: 1px solid white;
}
.new-btn-mmf {
  color: #2fc49e;
  background-color: white;
  border: 1px solid #2fc49e;
}
.new-btn-mmf:hover {
  background-color: #2fc49e;
  color: white;
  border: 1px solid white;
}
.new-btn-website {
  color: #133aff;
  background-color: white;
  border: 1px solid #133aff;
}
.new-btn-website:hover {
  background-color: #133aff;
  color: white;
  border: 1px solid white;
}

.new-carousel-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  
  object-position: top center;
}

.new-invisible {
  visibility: hidden;
  height: 1px;
  width: 1px;
}
.new-carousel-img:hover {
  cursor: zoom-in;
}
.new-image-viewer {
  z-index: 2000;
}
.new-save-button:hover {
  cursor: pointer;
}
.skeleton-1 {
  width: 100%;
  height: 300px;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-2 {
  width: 100%;
  height: 50px;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-3 {
  width: 100%;
  height: 150px;
  opacity: 0.7;
  animation: skeleton-loading 700ms linear infinite alternate;
}
.see-pics {
  color: rgb(212, 85, 0);
  transition: all 2000ms;
}
.new-see-pics:hover {
  color: rgb(0, 39, 212);
  cursor: pointer;
}

.new-skeleton-img {
  position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px; 

}

/* Different positions */

@keyframes skeleton-loading {
  0% {
    background-color: hsl(219, 13%, 78%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
